import {
  Navbar,
  Container,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap"
import Link from "next/link"
import cn from "classnames"
import { FormattedMessage } from "react-intl"
import { useIsFromAffiliateInvite } from "../../hooks/use-is-from-affiliate-invite"
import { useIsFromAffiliateSharedLink } from "../../hooks/use-is-from-affiliate-shared-link"
import { LanguageSwitch } from "../shared/language-swtich"
import { config } from "../../lib/config"
import { MoberriesLogo } from "../shared/moberries.logo"

const DefaultHeader = () => {
  const isFromAffiliateInvitation = useIsFromAffiliateInvite()
  const isFromAffiliateSharedLink = useIsFromAffiliateSharedLink()
  const isFromAffiliate = isFromAffiliateSharedLink || isFromAffiliateInvitation

  return (
    <Navbar color="dark" dark>
      <Container>
        <NavbarBrand className="mr-auto" tag="div">
          <MoberriesLogo isClickable={!isFromAffiliate} />
        </NavbarBrand>

        <Nav
          navbar
          className={cn("flex-row align-items-center", {
            "d-none": isFromAffiliate,
          })}
        >
          <NavItem>
            <Link href="/jobs">
              <a className="nav-link mr-3 text-white">
                <FormattedMessage
                  id="default.header.catalogHome"
                  defaultMessage="Jobs"
                />
              </a>
            </Link>
          </NavItem>
          <NavItem>
            <NavLink
              href={`${config.urls.candidateApp}/signup`}
              target="_blank"
              rel="noopener noreferrer"
              className="text-white"
            >
              <FormattedMessage
                id="default.header.signUp"
                defaultMessage="Sign up"
              />
            </NavLink>
          </NavItem>
          <NavItem>
            <LanguageSwitch
              color="link"
              className="text-decoration-none ml-2 text-white"
            />
          </NavItem>
        </Nav>
      </Container>
    </Navbar>
  )
}

export default DefaultHeader
