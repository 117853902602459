import React from "react"
import { config } from "../../lib/config"

export const MoberriesLogo: React.FC<{
  isClickable?: boolean
  link?: string
}> = ({ isClickable = true, link = config.urls.homepage }) => {
  if (isClickable) {
    return (
      <a href={link}>
        <img
          src="/web-catalog-app/assets/mb-logo-home.svg"
          alt="Moberries"
          width={185}
          height={40}
        />
      </a>
    )
  }

  return (
    <img
      src="/web-catalog-app/assets/mb-logo-home.svg"
      alt="Moberries"
      width={185}
      height={40}
    />
  )
}
